<template>
  <div class="ctx-welcome-view">
    <img :src="img" alt="item" />
    <h4>Congratulations! you have verify your email address</h4>
    <p>You can login to connectxone</p>
    <div class="ctx-login-button" @click="login">Login</div>
  </div>
</template>

<script>
import Welcome from "../../../assets/images/auth/verify.gif"
import Toastify from 'toastify-js';
export default {
  data(){
    return {
      img:Welcome,
    }
  },
  components:{},
  methods:{
    login(){
      this.$router.push('/login').catch(() => { });
    },
    sendVerificationCode(code){
        window.cxSocketApi.talk('validate-code', (response) => {
           Toastify({
                    text: `${response.data} !`,
                    gravity: "top", // `top` or `bottom`
                    position: "center", // `left`, `center` or `right`
                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                      }
                    }).showToast();  
      return response;
    }, (error) => {
       
             Toastify({
                text: `${error.data} !`,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                duration: 3000,
                style: {
                    background: "#d0021b",
                  }
                }).showToast();
      return error;
    }, code)
    }
  },

  mounted() {
    const query = this.$route?.query;
    const code = query.code;
    this.sendVerificationCode(code);
  },
}
</script>

<style>

</style>